/* loading-wave.css */

.loading-wave {
  display: flex;
  align-items: center;
  background-color: transparent; /* Fondo transparente */
  padding: 5px; /* Ajustar el espacio interno si es necesario */
  border: 1px solid #fff; /* Añadir un borde blanco */
  border-radius: 5px; /* Ajustar el radio de borde según sea necesario */
}

.dot-wave-container {
  position: relative;
  width: 36px; /* Ajusta el ancho según sea necesario */
  height: 10px; /* Ajusta la altura según sea necesario */
}

.dot-wave {
  width: 10px;
  height: 10px;
  background-color: #000; /* Puntos negros */
  border-radius: 50%;
  position: absolute;
  animation: wave 1.5s linear infinite;
}

.dot-wave:nth-child(1) {
  left: 0;
  animation-delay: 0.5s;
}

.dot-wave:nth-child(2) {
  left: 50%;
  animation-delay: 0s;
}

.dot-wave:nth-child(3) {
  left: 100%;
  animation-delay: 0.5s;
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(50%);
  }
}

