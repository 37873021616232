.dot-password {
    width: 20px;
    height: 20px;
    background-color: #ccc; /* Color de fondo para los dots inactivos */
    border-radius: 50%; /* Forma redondeada para los dots */
    display: inline-block;
    margin-right: 10px; /* Espacio entre los dots */
  }
  
  /* Estilos para los dots activados */
  .dot-password.active {
    background-color: #3498DB; /* Color de fondo para los dots activados */
  }
  
 