body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-teal{
  background-color: #3498DB	 !important;
}
.bg-white{
  background-color: white !important;
}
.bg-yellow{
  background-color: #FFD700 !important;
}
.bg-gainsboro{
  background-color: #DCDCDC !important;
}
.bg-lightgrey{
  background-color: #D3D3D3 !important;
}
.bg-silver{
  background-color: #C0C0C0 !important;
}
.text-darkorange
{
  color:#FFD700;
}
.text-teal{
  color:#3498DB !important;
}

.text-darkslateblue{
  color:#483D8B !important;
}

body,html{
  background-color: #EAECEE;
  user-select: none;
}

.font-quicksand{
  font-family: 'Quicksand', sans-serif;
}

.btn-teal {
  background-color: #3498DB;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.btn-teal:hover {
  background-color: #3498DB; 
  color:white;
}

.btn-outline-teal {
  background-color: transparent;
  border: 2px solid #3498DB;
  color: #3498DB;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.btn-outline-teal:hover {
  background-color: #3498DB;
  color: #fff;
  border: 2px solid #3498DB;
}

.btn-outline-teal:active {
  background-color: #3498DB; /* Cambiado para el estado activo */
  color: #fff; /* Cambiado para el estado activo */
  border: 2px solid #3498DB; /* Cambiado para el estado activo */
}

.btn-outline-teal:focus {
  outline: none; /* Quita el contorno predeterminado al enfocar el botón */
}

.hover{
  user-select: none;
  cursor: pointer;
}

.app-caracteristicas-imagen{
  height: 75px;
  width: 75px;
}

.text-bg-azure {
  display: inline-block;
  padding: 5px 5px;
  margin:2px;
  background-color: #fff; /* Color de fondo personalizado */
  color: #3498DB; /* Color del texto */
  border-radius: 5px; /* Bordes redondeados */
  border: 1px solid #3498DB;
  font-weight: normal;
}

.text-bg-teal {
  display: inline-block;
  padding: 5px 5px;
  margin:2px;
  background-color: #3498DB; /* Color de fondo personalizado */
  color: white; /* Color del texto */
  border-radius: 5px; /* Bordes redondeados */
  border: 1px solid #3498DB;
  font-weight: normal;
}

.img-login-xs{
  width: 35px;
  height: 35px;
}

.img-login-lg{
  width: 130px;
  height: 130px;
}

zindex-1000{
  z-index: 1000;
}