/* Confirm.css */
.confirm {
    background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro transparente */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
  }
  
  .confirm-content {
    background-color: #fff;
    padding: 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #000; /* Texto en color negro */
  }
  
  .button-container {
    margin-top: 10px;
  }
  
  button {
    margin: 0 5px;
    padding: 8px 12px;
    cursor: pointer;
  }