/* Estilo para el componente postal */
.postal {
  position: fixed;
  top: 0;
  left: -100%; /* Comienza fuera de la pantalla a la izquierda */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que el postal esté por encima de otros elementos */
  animation: slideIn .5s forwards; /* Utiliza una animación llamada 'slideIn' con una duración de 1 segundo */
}

/* Estilo para el contenido dentro del postal */
.postal-content {
  background: #DCDCDC; /* Fondo del contenido */
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/* Define la animación 'slideIn' */
@keyframes slideIn {
  from {
      left: 100%; /* Comienza completamente a la derecha */
  }
  to {
      left: 0; /* Termina en la posición visible (izquierda) */
  }
}

/* Estilo para mostrar el postal */
.postal.active {
  left: 0; /* Muestra el postal en la posición izquierda */
}