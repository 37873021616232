/* Estilo para el fondo oscuro detrás del modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
  }
  
  /* Estilo para el contenedor del modal */
  .modal-container {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    width: 90%;
  }
  
  /* Estilo para el botón de cerrar el modal */
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: transparent;
    font-size: 24px;
    color: #fff; /* Color del icono de cierre */
    border: none;
  }
  

