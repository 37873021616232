.step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
  margin-top: 20px;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step span {
  width: 30px;
  height: 30px;
  border: 2px solid #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  z-index: 1; 
}

.step p {
  margin: 0;
  font-size: 12px;
}

.step.active span {
  background-color: #3498DB;
  color: #fff;
  border: 2px solid #3498DB;
}

/* Nueva regla para las líneas horizontales */
.step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  height: 2px;
  width: 50px;
  background-color: #333;
  transform: translateY(-1px); /* Ajusta la posición vertical para centrar */
  margin-top: -1px; /* Ajusta según la mitad del grosor de la línea */
  z-index: 0; /* Detrás del número del paso */
}