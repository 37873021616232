.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.loading-content {
    background: white;
    width: 100%;
    height: 100%;
}

.loading-content p {
    user-select: none; 
}

.loading-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #008080;
    border-radius: 50%;
    margin: 0 5px;
    animation: dot-pulse 1s infinite;
  }
  
  @keyframes dot-pulse {
    0%, 20%, 100% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
  }