.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5); /* Opacidad gris */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .added-text {
    color: red; /* Color rojo */
    font-size: 24px; /* Tamaño de fuente, ajusta según sea necesario */
    font-weight: bold;
    transform: rotate(315deg); /* Rotar 45 grados */
  }