/* En tu archivo de estilos CSS o en línea si es necesario */

/* Estilos para el icono de carrito */
.fa-cart-shopping {
    font-size: 24px;
    color: white;
    cursor: pointer; /* Agrega un puntero para indicar que es interactivo */
  }
  
  /* Estilos para el contador en el círculo rojo */
  .cart-counter {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    min-width: 18px; /* Ajusta el ancho mínimo según tus necesidades */
    height: 18px; /* Ajusta la altura según tus necesidades */
  }

  /* En tu archivo de estilos CSS o en línea si es necesario */

/* Estilos para el botón circular del carrito */
.cart-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #3498DB;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  /* Estilos para el icono de carrito dentro del botón */
  .cart-button i {
    font-size: 24px;
  }