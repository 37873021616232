.c-timeline__item {
  position: relative;
  display: flex;
  gap: 1.5rem;

  &:last-child {
    .c-timeline__content {
      &:before {
        display: none;
      }
    }
  }
}

.c-timeline__content {
  flex: 1;
  position: relative;
  order: 1;
  padding-left: 2.5rem;
  padding-bottom: 3rem;

  &:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: lightgrey;
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(0px - 11px);
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    z-index: 1;
    border: 2px solid lightgrey;
    border-radius: 50%;
  }
}

.c-timeline__title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.c-timeline__desc {
  color: grey;
}

time {
  text-align: end;
  flex: 0 0 78px;
  min-width: 0;
  overflow-wrap: break-word;
  padding-bottom: 1rem;
}

/*** Non-demo CSS ***/

.wrapper {
  max-width: 100%;
  margin: 1rem auto 0;
  font-size: 15px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
