/* Estilo para el fondo oscuro detrás del BottomSheet */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Alinea el contenido en la parte inferior */
  z-index: 1500;
}

/* Estilo para el contenedor del BottomSheet */
.bottom-sheet {
  user-select: none;
  background: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-width: 100%;
  width: 100%;
  padding: 20px;
  position: fixed; /* Cambiar a "fixed" para que se mantenga en su lugar */
  bottom: 0; /* Alinear en la parte inferior de la pantalla */
  left: 0; /* Alinear en el borde izquierdo de la pantalla */
  z-index: 2000;
  transition: transform 0.3s;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.3);
  animation: slideUp 0.3s ease-out forwards;
}
  
  /* Estilo para el BottomSheetDialog activo */
  .bottom-sheet.active {
    transform: translateY(0);
  }
  
  /* Estilo para el título del BottomSheetDialog */
  .bottom-sheet h2 {
    font-size: 1.5rem;
    margin: 0 0 20px;
    text-align: center;
    color: #333;
  }
  
  /* Estilo para los campos del formulario en el BottomSheetDialog */
  .bottom-sheet input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  /* Estilo para el botón de inicio de sesión en el BottomSheetDialog */
  .bottom-sheet button {
    width: 100%;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Estilo para el botón de inicio de sesión al pasar el ratón sobre él en el BottomSheetDialog */
  .bottom-sheet button:hover {
    background: #0056b3;
  }
  
  /* Animación de entrada desde abajo */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .icono{
    width: 15px;
    height: 15px;
  }

  input {
    user-select: none;
    -webkit-user-select: none; /* Para navegadores basados en WebKit, como Chrome y Safari */
    -moz-user-select: none;    /* Para navegadores basados en Gecko, como Firefox */
    -ms-user-select: none;     /* Para Internet Explorer */
    border: none !important;     
    outline: none;             /* Quitar el borde cuando está enfocado */
  }

  input:focus {
    border-color: #007bff; /* Cambiar el color del borde al azul cuando el input está enfocado */
  }
  
  /* Estilo para la línea vertical */
  input:focus + .focus-line {
    width: 100%; /* Ancho completo para cubrir el input */
    background-color: #007bff; /* Cambiar el color de fondo al azul cuando el input está enfocado */
  }